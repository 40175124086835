import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/seo'
import Squiggle from '../components/Squiggle'

const MailingListPage = () => (
  <Layout>
    <SEO title="Thanks!" />
    <h1>Welcome aboard!</h1>
    <p>You'll now recieve updates via email when new items are added.</p>

    <Squiggle
      type={6}
      color={'#5601FF'}
      style={{
        position: 'static',
        display: 'block',
      }}
    />

    <Link to={'/'}>Back to home</Link>
  </Layout>
)

export default MailingListPage
